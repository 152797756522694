@import '../fonts.css';

.healthshield-page {
  width: 100%;
  height: 100vh;
  font-family: 'LatoRegular', sans-serif;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.header-logo-wrapper {
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-content {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.title-content-wrapper {
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;
}

.title-content-background-wrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.title-content-background-first {
  position: absolute;
  left: 0;
  top: 0;
  height: 501.5px;
  width: 75%;
  background: linear-gradient(135deg, #2cb5d6 0%, #2cb5d6 24.78%, #62ebb9 100%),
    linear-gradient(
      135deg,
      rgba(98, 235, 185, 0.9) 0%,
      rgba(98, 235, 185, 0.5) 100%
    );
  z-index: 2;
  clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
}

.title-content-background-second {
  position: absolute;
  left: 0;
  top: 0;
  height: 501px;
  width: 75%;
  background: linear-gradient(
    135deg,
    rgba(98, 235, 185, 0.9) 0%,
    rgba(98, 235, 185, 0.5) 100%
  );
  z-index: 1;
  clip-path: polygon(0 0, 95% 0, 70% 100%, 0% 100%);
}

.title-content-background-image {
  position: absolute;
  right: 0;
  top: 0;
  height: 501px;
  width: 979px;
  background: url('../../images/backgroundHeader.jpg') no-repeat center center;
  background-size: cover;
}

.title-content-text {
  width: 100%;
  height: 501px;
  z-index: 3;
}

.title-content-text .inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-content-text-main-title {
  font-size: 56px;
  margin-bottom: 56px;
  color: #fff;
  max-width: 400px;
}

.title-content-text-main-subtitle {
  font-size: 18px;
  line-height: 22px;
  color: #000;
}

.options-content-wrapper {
  padding: 55px 0;
}

.options-title {
  font-size: 18px;
  line-height: 22px;
  color: #000;
  text-align: left;
}

.options-items {
  padding: 75px 0;
}

.options-content-list-item {
  max-width: 235px;
  text-align: center;
}

.options-content-list-item.results-online {
  max-width: 216px;
}

.options-content-list-item.results-online
  .options-content-list-item-image-shadow {
  display: none;
}

.options-content-list-item-image-shadow {
  height: 4px;
  width: 80px;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.19) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 40px/2px;
}

.options-content-list-item-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 166px;
  margin-bottom: 20px;
}

.options-content-list-item-image {
  margin-bottom: 15px;
}

.options-content-list-item-title {
  font-family: 'LatoBold', sans-serif;
  min-height: 54px;
  font-size: 22px;
  margin-bottom: 18px;
  letter-spacing: 0;
  line-height: 27px;
  color: #2cb5d6;
}

.options-content-list-item-subtitle {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.options-content-list {
  justify-content: space-between;
}

.options-coupon-code-links {
  justify-content: space-between;
}

.options-coupon-code-title {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 30px;
  color: #000000;
  font-family: 'LatoBold', sans-serif;
}

.options-coupon-code-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 312px;
  padding: 8px 12px;
  color: #000000;
  font-family: 'LatoBlack';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-decoration: none;
}

.options-coupon-code-links-first {
  position: relative;
  margin-right: 72px;
}

.border-gradient {
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #62ebb9, #2cb5d6);
  border-radius: 3px;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #ff520b;
}

.options-coupon-code-links-wrapper {
  margin-bottom: 20px;
}

.options-coupon-code-link-subtitle {
  color: #000000;
  font-family: 'LatoRegular';
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
}

.options-coupon-code-terms-link {
  color: #000000;
  font-family: 'LatoRegular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.how-it-works-content-wrapper {
  padding: 60px 0 40px;
  max-width: 2400px;
  margin: 0 auto;
  background: linear-gradient(90deg, #2cb5d6 0%, #62ebb9 100%);
}

.how-it-works-titles {
  text-align: center;
  font-family: 'LatoBold';
  letter-spacing: 0;
  padding-bottom: 40px;
}

.how-it-works-title {
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 22px;
}

.how-it-works-subtitle {
  font-size: 28px;
  line-height: 34px;
  color: #000;
}

.how-it-works-items {
  margin-bottom: 71px;
  margin-top: 40px;
}

.how-it-works-list {
  justify-content: center;
}

.how-it-works-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 154px;
}

.how-it-works-item:not(:last-of-type) {
  margin-right: 26px;
}

.how-it-works-item-img-wrapper {
  position: relative;
  margin-bottom: 40px;
}

.how-it-works-item-title {
  margin-bottom: 21px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  color: #ffffff;
  font-family: 'LatoBold';
}

.how-it-works-item-subtitle {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  color: #000000;
  font-family: 'LatoRegular';
}

.how-it-works-item-img-arrow {
  position: absolute;
  left: 50%;
  width: 192px;
  height: 39px;
  z-index: 5;
}

.arrow-top {
  top: -20px;
}

.arrow-bottom {
  bottom: -20px;
}

.how-it-works-links-wrapper {
  width: 100%;
  text-align: center;
}

.how-it-works-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 261px;
  padding: 8px 12px;
  margin-bottom: 12px;
  border: 1px solid #000000;
  border-radius: 3px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
}

.how-it-works-link-subtitle {
  color: #000000;
  font-family: 'LatoRegular';
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
}

@media (max-width: 1024px) {
  .inner-content {
    max-width: 660px;
  }

  .title-content-background-first {
    clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  }

  .title-content-background-second {
    clip-path: polygon(0 0, 95% 0, 50% 100%, 0% 100%);
  }

  .options-content-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .options-content-list-item:not(:last-of-type) {
    margin-bottom: 80px;
  }

  .options-coupon-code-link {
    min-width: 288px;
  }

  .options-coupon-code-links-first {
    margin-right: 42px;
  }

  .options-coupon-code-links-wrapper {
    text-align: center;
    margin-bottom: 30px;
  }

  .options-coupon-code-links {
    margin-bottom: 20px;
  }

  .how-it-works-list {
    flex-wrap: wrap;
  }

  .how-it-works-item-img-arrow.step3 {
    display: none;
  }

  .how-it-works-item:nth-child(1) {
    margin-bottom: 60px;
  }

  .options-coupon-code-link-subtitle {
    position: absolute;
    width: 100%;
    top: 60px;
    left: 55%;
  }
}

@media (max-width: 767px) {
  .inner-content {
    max-width: 290px;
  }

  .title-content-background-second {
    display: none;
  }

  .title-content-background-first {
    height: 239px;
    width: 100%;
    top: auto;
    bottom: 0;
    background: linear-gradient(
      25.72deg,
      #2cb5d6 0%,
      #48d1c7 52.43%,
      rgba(89, 226, 190, 0.5) 81.83%,
      rgba(97, 234, 185, 0.3) 100%,
      rgba(98, 235, 185, 0.3) 100%
    );
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);
  }

  .title-content-background-image {
    height: 365px;
    width: 713px;
  }

  .title-content-text {
    height: auto;
  }

  .title-content-text-main-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 26px;
  }

  .title-content-text-main-subtitle {
    font-size: 12px;
    line-height: 15px;
  }

  .options-coupon-code-links {
    flex-direction: column;
    margin-bottom: 0;
  }

  .title-content-wrapper {
    height: 365px;
  }

  .title-content-background-wrapper {
    height: 100%;
  }

  .title-content-text .inner-content {
    justify-content: flex-end;
  }

  .title-content-text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 240px;
  }

  .how-it-works-item-img-arrow {
    display: none;
  }

  .options-coupon-code-link {
    max-width: 276px;
    margin-bottom: 10px;
  }

  .options-coupon-code-links-first {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .options-title {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 60px;
  }

  .options-items {
    order: 2;
  }

  .options-coupon-code-wrapper {
    order: 1;
  }

  .options-coupon-code-title {
    text-align: center;
  }

  .options-coupon-code-link-subtitle {
    position: relative;
    width: auto;
    top: auto;
    left: auto;
  }

  .options-coupon-code-links-wrapper {
    margin-bottom: 0;
  }

  .options-coupon-code-terms-link {
    font-size: 10px;
    line-height: 12px;
  }

  .options-content-list-item-title {
    max-width: 200px;
    min-height: auto;
  }

  .options-content-list-item {
    align-items: center;
  }

  .options-content-list-item:not(:nth-last-child()) {
    margin-bottom: 60px;
  }

  .how-it-works-content-wrapper {
    background: linear-gradient(180deg, #2cb5d6 0%, #62ebb9 100%);
  }

  .how-it-works-item:not(:last-of-type) {
    margin-right: 0;
  }

  .how-it-works-item {
    margin-right: 0px;
    margin-bottom: 60px;
  }

  .how-it-works-link-subtitle {
    font-size: 10px;
    line-height: 12px;
  }
}
