@font-face {
  font-family: RubikMedium;
  src: url(../fonts/Rubik/Rubik-Medium.ttf) format('ttf'),
    url(../fonts/Rubik/Rubik-Medium.woff) format('woff'),
    url(../fonts/Rubik/Rubik-Medium.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: LatoBlack;
  src: url(../fonts/Lato/Lato-Black.ttf) format('ttf'),
    url(../fonts/Lato/Lato-Black.woff) format('woff'),
    url(../fonts/Lato/Lato-Black.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: LatoBold;
  src: url(../fonts/Lato/Lato-Bold.ttf) format('ttf'),
    url(../fonts/Lato/Lato-Bold.woff) format('woff'),
    url(../fonts/Lato/Lato-Bold.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: LatoRegular;
  src: url(../fonts/Lato/Lato-Regular.ttf) format('ttf'),
    url(../fonts/Lato/Lato-Regular.woff) format('woff'),
    url(../fonts/Lato/Lato-Regular.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}
